import { React, styled, Link} from "../shipityo/index";
import Layout from "../layouts/index";

import Img from "gatsby-image";
import PageHeading from "../helpers/page-heading";

let downloads = [
    {
        name: "Family Law",
        headline: "",
        source: "/attorneys/family-law",
        category: "Category 3"
    },
    {
        name: "Labour Law",
        headline: "",
        source: "/attorneys/labour-law",
        category: "Category 1"
    },
    {
        name: "Personal Injury Law",
        headline: "",
        source: "/attorneys/personal-injury-law",
        category: "Category 2"
    },
    {
        name: "Human Rights Law",
        headline: "",
        source: "/attorneys/human-rights-law",
        category: "Category 1"
    },
    {
        name: "General Civil Litigation",
        headline: "",
        source: "/attorneys/civil-litigation-law",
        category: "Category 3"
    }
]

const Container = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    color: #6a6a6a;
    @media only screen 
    and (max-width: 650px)  {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    } 
    .disclaimer{
        max-width: 450px;
        margin: auto;
        background: #848484;
        padding: 20px;
        border-radius: 15px;
        color: #fff;
        margin-bottom: 20px;
        margin-top: 40px;
    }
`;

const ContentInner = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    @media only screen 
    and (max-width: 650px)  {
        grid-template-columns: 1fr 1fr;
    } 
    @media only screen 
    and (max-width: 400px)  {
        grid-template-columns: 1fr;
    } 
    a {
        color:#4d4d4d;
        margin: 0px;
    }
    h3{
        margin: 0px;
    }
    p{
        margin: 0px;
    }
    .download-item{
        
    }
    hr{
        margin: 0px;
    }
    .download-item{
        transition-duration: 0.5s;
        padding: 30px;
        padding: 20px;
        :hover {
            background: #39393B;
            border-radius: 15px;
            color: #fff;
            box-shadow: 5px 10px 10px rgba(0, 0, 0, .20);
            .read-button {
                background: #FCE20A;
                color: #000;
            }
            h3, p {
                color: #FFF;
            }
        }
        h3 {
            text-transform: uppercase;
            text-align: left;
            margin-bottom: 20px;
        }
        p {
            text-align: left;
            margin-bottom: 15px;
        }
        .read-button{
            text-align: center;
            transition-duration: 2s;
            background: #000;
            color: #fff;
            border-radius: 5px;
            padding-top: 4px;
            padding-bottom: 4px;
            margin-left: 0px;
            max-width: 120px;
            box-shadow: 5px 10px 10px rgba(0, 0, 0, .20);
            :hover {
                box-shadow: 5px 5px 100px 50px rgba(255, 255, 255, .50);
                cursor: pointer;

            }
        }
    }
`;  

class Component extends React.Component {

    constructor({data}) {
        let  pageImages =[];
        super();
        this.state = {
        };
        this.render = this.render.bind(this);
        if(data){
            if(data.allFile){
                if(data.allFile.edges){
                    pageImages = data.allFile.edges
                    this.state = {pageImages: pageImages};
                } 
            } 
        }
    }
  
    render() {
        return (
            <Layout>
            <div>
                <div id="home-images">
                    {this.state.pageImages.map((image, index) => (
                        <div key={index}>
                            <Img key={index} sizes={image.node.childImageSharp.sizes} />
                        </div>
                    ))}
                </div>
                <Container>
                    <PageHeading>
                        <span className="heading-top">STAY INFORMED</span><br/>
                        <br/>
                        <hr className="heading-line"/><br/>
                        <span className="heading-main">RELEVANT INDUSTRY INFORMATION</span>
                    </PageHeading>
                    
                    <ContentInner>
                        {
                            downloads.map(function(download, i){
                                return <div key={i} className="download-item">
                                    <Link  to={download.source}>
                                        <h3>{download.name}</h3>
                                        <p>{download.headline}</p>
                                        <div className="read-button">Read More ...</div>
                                    </Link>
                                </div>
                            })
                        }
                    </ContentInner>
                    <p className="disclaimer">These articles are intended for informational purposes and does not constitute legal advice. Please contact our office for professional legal guidance relating to your personal circumstances.</p>
                 </Container>
            </div>
            </Layout>
            
        ) 
    }
  }


  export default Component;
  
  export const query = graphql`
    query ArticlesQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: {relativeDirectory: {regex: "/img/pagebanners/downloads/"}}) {
        edges {
          node {
            name
            relativePath
            prettySize
            extension
            birthTime
            childImageSharp {
              sizes(maxWidth: 1900) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
            }
          }
        }
      }
    }
  `